import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromModule from '../../ngrx/reducers';
import { AccountActions, ReservationActions, UserInfoActions } from '../../ngrx/actions';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { cueUser, cueClose, IconComponent } from '@cue/ui/icons';
import { AddinConfigService } from '@outlook-addin/shared';
import { translation } from '@assist/shared/translations';
import { DropdownlistComponent, ItemTemplateDirective, ValueTemplateDirective } from '@cue/assist/ui/dropdowns';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Localization, LOCALIZATIONS } from '@cue/translate';
import { AuthHttpService, OutlookReservationMode, STORAGE_KEYS } from '@outlook-addin/cue-http';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'addin-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [
    IconComponent,
    DropdownlistComponent,
    ItemTemplateDirective,
    TranslocoModule,
    ValueTemplateDirective,
    ReactiveFormsModule,
    RouterLink,
    AsyncPipe,
  ],
})
export class MenuComponent {
  outlookReservationMode = OutlookReservationMode;
  currentOutlookReservationMode$: Observable<OutlookReservationMode>;

  ICONS = {
    cueUser,
    cueClose,
  };
  protected readonly translation = translation;

  protected instalationName: string;
  userInfo$ = this.store.select(fromModule.selectPackedUserInfo);
  currentLanguageControl = new FormControl<{ text: string; flag: string }>(null);
  public languageItems: Array<{ text: string; flag: string }> = [
    { text: 'CS', flag: 'cs.svg' },
    { text: 'CA', flag: 'ca.svg' },
    { text: 'UA', flag: 'ua.svg' },
    { text: 'EN', flag: 'en.svg' },
    { text: 'DE', flag: 'ge.svg' },
    { text: 'ES', flag: 'es.svg' },
    { text: 'FR', flag: 'fr.svg' },
  ];

  get loginUrl(): string {
    return `https://login.microsoftonline.com/${this.configService.value.configuration.tenantId}/oauth2/v2.0/authorize?client_id=${this.configService.value.configuration.clientId}
    &scope=Mail.Read offline_access openid profile User.Read&response_type=code&prompt=${this.configService.value.configuration.prompt}&redirect_uri=${`https://${window.location.host}/Authorize2`}`;
  }

  mobileHamburger = false;

  constructor(
    private store: Store<fromModule.State>,
    private router: Router,
    private configService: AddinConfigService,
    public translocoService: TranslocoService,
    @Optional() @Inject(LOCALIZATIONS) private localizations: Localization[][],
  ) {
    this.languageItems = this.configService.value.configuration.languages
      .filter((x) => x.activeInAssist)
      .map((x) => {
        return {
          text: x.code.toUpperCase(),
          flag: `${x.code}.svg`,
        };
      });

    this.currentOutlookReservationMode$ = this.store.select(fromModule.selectOutlookReservationMode);
    this.instalationName = this.configService.value.configuration.installationName;
    if (this.isUserLoggedIn()) {
      this.store.dispatch(UserInfoActions.loadUserInfo());
    }

    this.currentLanguageControl.setValue(this.currentLanguage, {
      emitEvent: false,
    });
    this.currentLanguageControl.valueChanges.subscribe((val) => {
      this.currentLanguage = val;
    });
  }

  get currentLanguage(): { text: string; flag: string } {
    const availableLangs: any[] = this.translocoService.getAvailableLangs();
    const langFromLocalStorage = localStorage.getItem('addin-lang');
    if (langFromLocalStorage != undefined) {
      const found = availableLangs.find((x) => x == langFromLocalStorage);
      return this.languageItems.find((x) => x.text == (found ?? this.translocoService.getDefaultLang()).toUpperCase())!;
    } else {
      const found = availableLangs.find((x) => x == navigator.language.slice(0, 2));
      return this.languageItems.find((x) => x.text == (found ?? this.translocoService.getDefaultLang()).toUpperCase())!;
    }
  }

  set currentLanguage(value: { text: string; flag: string }) {
    const availableLangs: any[] = this.translocoService.getAvailableLangs();
    const found = availableLangs.find((x) => x == value.text.toLowerCase());

    if (found) {
      const translation = this.localizations[0].find((x) => x.language == found)!;
      translation.valueGetter().then((x) => {
        localStorage.setItem('addin-lang', found);
        this.translocoService.setTranslation(x, translation.language, {
          merge: false,
        });
        this.translocoService.setActiveLang(found);
      });
    }
  }

  loginWithMsDialog() {
    Office.onReady().then((x) => {
      console.log(this.loginUrl);
      Office.context.ui.displayDialogAsync(this.loginUrl, { height: 20, width: 30, displayInIframe: false }, (asyncResult) => {
        const dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogEventReceived, (args) => {
          // console.log(args);
          this.store.dispatch(AccountActions.loadTokenRefreshToken({ route: false }));
        });
      });
    });
    //Close hamburger menu
    this.mobileHamburger = false;
  }

  isUserLoggedIn(): boolean {
    return localStorage.getItem(STORAGE_KEYS.code) != null;
  }

  // logout(){
  //
  //   this.authHttpService.signOut().subscribe(()=> {
  //     this.store.dispatch(UserInfoActions.clearUserInfo());
  //     this.mobileHamburger = false;
  //     this.cdr.markForCheck();
  //   });
  // }

  navigateToReservations() {
    this.router.navigate(['reservations']);
    //Close hamburger menu
    this.mobileHamburger = false;
  }

  navigateToReservationList() {
    this.store.dispatch(
      ReservationActions.outlookReservationModeChange({
        reservationMode: OutlookReservationMode.NotReservationDetail,
      }),
    );
    this.router.navigate(['reservations']);
  }
}
