import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'addin-appointment-edit',
  templateUrl: './appointment-edit.component.html',
  styleUrls: ['./appointment-edit.component.scss'],
  imports: [ReactiveFormsModule, RouterModule],
})
export class AppointmentEditComponent {
  form: FormGroup;
  appointmentBody: string;
  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      emailBody: [null, Validators.required],
    });
  }

  performOutlookAction() {
    const itemType = Office.context.mailbox.item.itemType as Office.MailboxEnums.ItemType;
    if (itemType == Office.MailboxEnums.ItemType.Message) {
      const itemData = Office.context.mailbox.item as Office.MessageRead;
      this.performMailAction();
    } else if (itemType == Office.MailboxEnums.ItemType.Appointment) {
      const itemData = Office.context.mailbox.item as Office.AppointmentRead;
      this.performAppointmentAction(itemData);
    }
  }

  private performAppointmentAction(item: Office.AppointmentRead) {
    item.body.getAsync('text', (x) => {
      this.appointmentBody = x.value;
    });
  }

  private performMailAction() {
    Office.context.mailbox.displayNewAppointmentForm({
      start: new Date(),
      end: new Date(),
      requiredAttendees: [
        {
          emailAddress: 'stonawski.jakub@gmail.com',
          displayName: 'Jakub',
          appointmentResponse: 'organizer',
          recipientType: 'user',
        },
      ],
      optionalAttendees: [],
      resources: [],
      location: 'OFFICE',
      subject: 'POTREBA SE POTKAT DNES',
      body: 'Je opravdu potřeba zařídít nějakou schůzku',
    });
  }

  saveChanges() {
    if (this.form.valid) {
      Office.context.mailbox.item.body.setAsync(this.form.value.emailBody, (x) => {
        console.log(x);
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
