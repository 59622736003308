import {
  Component,
  ChangeDetectionStrategy,
  Input,
  NgZone,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
  AfterViewChecked,
} from '@angular/core';
import * as L from 'leaflet';
import { ImageOverlay } from 'leaflet';
import { of, Subject, take } from 'rxjs';
import { ResponsiveService } from '@cue/responsivity';

@Component({
  selector: 'addin-resource-map',
  templateUrl: './resource-map.component.html',
  styleUrls: ['./resource-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [],
})
export class ResourceMapComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('breadcrumbContainer', {
    static: true,
    read: ElementRef,
  })
  breadcrumbContainer: ElementRef<HTMLDivElement>;

  @Input() resourceId;

  _imageSrc: string;

  private _geoData: string;

  @Input() set geoData(value: string) {
    this._geoData = value;
  }

  get geoData(): string {
    return this._geoData;
  }

  @Input() set imageSrc(value: string) {
    this._imageSrc = value;
    this.setImageMap(value);
  }

  get imageSrc(): string {
    return this._imageSrc;
  }

  @ViewChild('mapElement', { static: true, read: ElementRef })
  mapElement!: ElementRef<HTMLDivElement>;
  private map: any;
  private imageOverlay!: ImageOverlay;
  private renderedLayers = new L.FeatureGroup([], {});

  reset$ = new Subject();

  constructor(
    private responsive: ResponsiveService,
    private zone: NgZone,
  ) {}

  ngAfterViewInit() {
    this.initMap();
    this.createNewLayer(this.geoData);
  }

  ngAfterViewChecked() {
    if (this.breadcrumbContainer) {
      this.breadcrumbContainer.nativeElement.scrollLeft = this.breadcrumbContainer.nativeElement.scrollWidth;
    }
  }

  private createNewLayer(areaGeoData: string) {
    const geoJson = JSON.parse(areaGeoData) as any;
    if (geoJson.properties == null) {
      geoJson.properties = {};
    }
    // if (areaGeoData.typeId == 2) {
    //
    //   geoJson.properties.resourceId = areaGeoData.resourceId;
    // }
    const geometryType = geoJson.type;

    const geo = L.geoJSON(geoJson, {
      style: {
        color: 'orange',
        fill: true,
        fillOpacity: 0.6,
        fillColor: 'orange',
      },
      pointToLayer: (feature, latlng) => {
        // if (areaGeoData.radius) {
        //   return new L.Circle(latlng, areaGeoData.radius);
        // } else {
        //   return new L.Marker(latlng, {});
        // }
        return new L.Marker(latlng, {});
      },
    });
    geo.addTo(this.renderedLayers);
    this.map.fitBounds(geo.getBounds().pad(5), {
      animate: false,
    });
  }

  private paintLayers(geoData) {
    if (geoData.loading) return;
    const existingLayers = this.renderedLayers
      .getLayers()
      .filter((x: any) => {
        if (x.getLayers) {
          return x.getLayers()[0];
        }
        return false;
      })
      .map((x: any) => x.getLayers()[0]);

    geoData.data.forEach((assistGeoData) => {
      if (assistGeoData.resourceId === this.resourceId) {
        this.createNewLayer(assistGeoData);
      }
    });
  }

  private prepareMapAndLayers() {
    this.responsive
      .isMobile()
      .pipe(take(1))
      .subscribe((isMobile) => {
        this.zone.runOutsideAngular(() => {
          this.map = L.map(this.mapElement.nativeElement, {
            preferCanvas: false,
            center: [0, 0],
            attributionControl: false,
            zoom: 1,
            minZoom: -1,
            maxZoom: 8,
            crs: L.CRS.Simple,
          } as any) as any;
        });

        this.map.addLayer(this.renderedLayers);
      });
  }

  private getImageMetadata(imageSrc: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      const img = new Image();
      img.addEventListener('load', function () {
        resolve({
          width: this.naturalWidth,
          height: this.naturalHeight,
        });
      });
      img.src = imageSrc;
    });
  }

  private initMap() {
    this.prepareMapAndLayers();
    if (this.imageSrc) {
      return this.setImageMap(this.imageSrc);
    }
    this.map.invalidateSize();
    return of(null);
  }

  private async setImageMap(imageSrc: string) {
    if (imageSrc) {
      const imageMetadata = await this.getImageMetadata(imageSrc);
      const width = imageMetadata.width;
      const height = imageMetadata.height;
      const southWest = this.map.unproject([0, height], 2);
      const northEast = this.map.unproject([width, 0], 2);
      const bounds = new L.LatLngBounds(southWest, northEast);
      if (this.imageOverlay) {
        this.imageOverlay.setUrl(imageSrc);
        this.imageOverlay.setBounds(bounds);
      } else {
        this.imageOverlay = L.imageOverlay(imageSrc, bounds, {
          zIndex: -1,
        }).addTo(this.map);
      }

      if (this.map) {
        this.map.fitBounds(this.imageOverlay.getBounds(), {
          animate: false,
        });
        this.map.invalidateSize();
      }
    }
  }

  ngOnDestroy(): void {
    this.reset$.next(null);
  }
}
