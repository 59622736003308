@if (resource) {
    <div class="room-detail">
        <div class="row">
            <div class="col col-12 col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        @if (resource.isAssignedTo) {
                            <div class="assigned-to-label">
                                <cue-icon [icon]="resource.isAssignedTo | assignTo"></cue-icon>
                                {{ resource.isAssignedTo | assignToTranslateKey | transloco }}
                            </div>
                        }
                        @if (canShowLinkToAssist) {
                            @if (resource.guid | resourceExternalUrl; as resourceUrl) {
                                <button class="link secondary" (click)="navigateToResourceDetailInAssist(resourceUrl)">
                                    <cue-icon
                                        [icon]="ICONS.cueExternalLink"
                                        [title]="translation.outlookAddin.cueExternalLink | transloco"
                                    ></cue-icon>
                                    Open in Assist
                                </button>
                            }
                        }
                        <table class="popup-table">
                            @if (resource.resourceDisplayNameForApp && resource.displayName) {
                                <tr>
                                    <th>
                                        <div>
                                            {{ translation.leftMenu.name | transloco }}
                                        </div>
                                    </th>
                                    <td>{{ resource.displayName }}</td>
                                </tr>
                            }
                            <tr>
                                <th>
                                    <div>
                                        {{ translation.leftMenu.type | transloco }}
                                    </div>
                                </th>
                                <td>{{ resource.resourceTypeName }}</td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        {{ translation.resourceDetail.capacity | transloco }}
                                    </div>
                                </th>
                                <td>{{ resource.capacity }}</td>
                            </tr>
                            @for (simpleFilter of getSimpleFiltersByResourceTypeId(resource); track simpleFilter) {
                                <tr>
                                    <th>
                                        <div>
                                            {{ simpleFilter.name }}
                                        </div>
                                    </th>
                                    <td>
                                        @if (simpleFilter.value === true) {
                                            <cue-icon [icon]="ICONS.cueCheck" class="correct"></cue-icon>
                                        }

                                        @if (simpleFilter.value === false) {
                                            <cue-icon [icon]="ICONS.cueClose" class="incorrect"></cue-icon>
                                        }

                                        @if (simpleFilter.value !== true && simpleFilter.value !== false) {
                                            {{ simpleFilter.value }}
                                        }
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </div>
            </div>
            <div class="col col-12 col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        @if (resource.breadcrumb) {
                            <div class="breadcrumb-container">
                                @for (item of resource.breadcrumb; track item; let i = $index) {
                                    <span class="breadcrumb">
                                        @if (i !== 0) {
                                            <cue-icon [icon]="ICONS.cueArrowRight"></cue-icon>
                                        }
                                        <span class="part">{{ item.displayName }}</span>
                                    </span>
                                }
                            </div>
                        }
                        <addin-resource-map
                            [geoData]="resource.mapGeoData"
                            [imageSrc]="resource.mapUrl"
                            [resourceId]="resource.guid"
                        ></addin-resource-map>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="text-align: center">
                        <cue-image
                            [imageClass]="'card-img-top'"
                            [imgHeight]="'180px'"
                            [loadWidth]="400"
                            [imgMaxWidth]="'438px'"
                            [imgWidth]="'100%'"
                            [src]="currentImage ? currentImage : resource.photos.length > 0 ? resource.photos[0].url : null"
                        >
                        </cue-image>
                        <div class="imgThumbsCont">
                            @for (image of resource.photos; track image) {
                                <cue-image
                                    (click)="currentImage = image.url"
                                    [loadWidth]="70"
                                    [imageClass]="'card-img-top'"
                                    [imgHeight]="'40px'"
                                    [imgMaxWidth]="'70px'"
                                    [imgWidth]="'70px'"
                                    [src]="image.url"
                                ></cue-image>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
