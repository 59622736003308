import { AfterViewInit, Component, ElementRef, HostBinding, inject, Input, OnDestroy } from '@angular/core';
import { cueIcon } from '../../library/icons';
import { BehaviorSubject, filter, Subscription, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IconsCacheService } from '../../services/icons-cache.service';

function isCueIcon(value: cueIcon | string): value is cueIcon {
  return (value as cueIcon).name !== undefined;
}

@Component({
  selector: 'cue-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16">
      <use></use>
    </svg>
  `,
  styleUrls: ['./icon.component.scss'],
  standalone: true,
})
export class IconComponent implements AfterViewInit, OnDestroy {
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() customIconClass: string | null = null;

  @Input() clearFill = true;
  private _sub?: Subscription;
  private _iconSubject = new BehaviorSubject<cueIcon | undefined | string>(undefined);
  private readonly elRef = inject(ElementRef);
  private readonly iconsCacheService = inject(IconsCacheService);
  private readonly http = inject(HttpClient);

  @Input() set icon(value: cueIcon | string) {
    this._iconSubject.next(value);
  }

  @Input() theme: 'primary-500' | 'error-500' | 'neutral' | 'neutral-inverse' = 'primary-500';

  @HostBinding('class')
  get elementClasses() {
    return {
      'transition-colors': true,
      'ease-in-out': true,
      'duration-100': true,
      'fill-primary-500': this.customIconClass == null && this.theme === 'primary-500',
      'fill-neutral-800': this.customIconClass == null && this.theme === 'neutral',
      'dark:fill-neutral-100': this.customIconClass == null && this.theme === 'neutral',
      'fill-neutral-100': this.customIconClass == null && this.theme === 'neutral-inverse',
      'dark:fill-neutral-800': this.customIconClass == null && this.theme === 'neutral-inverse',
      'fill-error-500': this.customIconClass == null && this.theme === 'error-500',
      'scale-50': this.size == 'small',
      [this.customIconClass!]: this.customIconClass != null,
    };
  }

  ngAfterViewInit() {
    this.loadCachedIcon();
  }

  private normalizeUrlToId(url: string): string {
    const finalUrl = url.replace(/[^a-zA-Z0-9-_:.]/g, '_');
    return 'cue_icon_url_' + finalUrl;
  }

  private loadCachedIcon() {
    const element = this.elRef.nativeElement;
    const svg = element.querySelector('svg') as SVGElement;

    this._sub = this._iconSubject
      .pipe(
        filter((value) => value !== undefined),
        tap((value) => {
          const name = isCueIcon(value) ? value.name : this.normalizeUrlToId(value as string);
          if (!this.iconsCacheService.isCached(name)) {
            if (isCueIcon(value)) {
              this.iconsCacheService.cache(name, value.data);
            } else {
              this.getRequest(value).subscribe((response) => {
                if (this.clearFill) {
                  const fill = 'fill="currentColor"';
                  const fillRegex = /fill="[^"]*"/g;
                  response = response.replace(fillRegex, fill);
                }
                this.iconsCacheService.cache(name, response);
              });
            }
          }
        }),
        switchMap((value) => {
          const name = isCueIcon(value) ? value.name : this.normalizeUrlToId(value as string);
          return this.iconsCacheService.getSvgSubject(name);
        }),
      )
      .subscribe((svgCache) => {
        svg.children[0].setAttribute('href', '#' + svgCache.domID);
        svg.setAttribute('viewBox', svgCache.viewBox);
      });
  }

  private getRequest(url) {
    return this.http.get(url! + '?xhr=true', { responseType: 'text' });
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }
}
