@if (groupedResourceList.loading || groupedResourceList.error == null) {
    <div class="safe-padding">
        <div class="switch">
            <div class="selected-bg" [class.all]="filterUpdate?.showOnlyAvailable === false"></div>
            <div (click)="showOnlyAvailable()" class="value" [class.active]="filterUpdate?.showOnlyAvailable === true">
                {{ translation.outlookAddin.onlyAvailable | transloco }}
            </div>
            <div (click)="showOnlyAvailable()" class="value" [class.active]="filterUpdate?.showOnlyAvailable === false">
                {{ translation.outlookAddin.allResources | transloco }}
            </div>
        </div>
        <assist-textbox [showClear]="true" [formControl]="search" [iconBefore]="ICONS.cueSearch"></assist-textbox>
    </div>
}

@if (groupedResourceList) {
    @if (groupedResourceList.loading) {
        <cue-loading></cue-loading>
    }
    <div class="resources-list">
        @if (!groupedResourceList.loading && groupedResourceList.error) {
            <div class="loading-error">
                {{ translation.outlookAddin.errorDescription | transloco }}
                <button (click)="refresh.emit()" class="small">
                    <cue-icon [icon]="ICONS.cueRefresh"></cue-icon>
                    {{ translation.outlookAddin.refresh | transloco }}
                </button>
            </div>
        }
        @if (!groupedResourceList.loading && !groupedResourceList.error) {
            <div>
                @for (group of groupedResourceList.data; track group; let i = $index) {
                    <div class="accordion" [class.expanded]="!isItemShrinked(i)">
                        <div class="accordion-header" (click)="expandShrinkGroup(i)">
                            <div class="first-part">
                                <p class="heading">
                                    {{ group.resourcePropertyName | localizedString }}
                                    <span class="description">({{ group.resources?.length }})</span>
                                </p>
                            </div>
                            <div class="end-part">
                                <div class="arrow">
                                    <cue-icon [icon]="ICONS.cueArrowDown"></cue-icon>
                                </div>
                            </div>
                        </div>
                        @if (!isItemShrinked(i)) {
                            <div class="accordion-content">
                                @if (group.resources?.length == 0) {
                                    <span>{{
                                        filterUpdate?.showOnlyAvailable
                                            ? (translation.outlookAddin.onlyAvailibleResourcesNotFound | transloco)
                                            : (translation.outlookAddin.resourcesNotFound | transloco)
                                    }}</span>
                                }

                                @for (item of group.resources; track item) {
                                    <div
                                        [class.selected]="isResourceSelected(item.resourceId)"
                                        class="resource-item"
                                        (click)="selectDeselectResource(item.resourceId, $event, item.isAssignedTo)"
                                        [class.active]="isResourceSelected(item.resourceId)"
                                    >
                                        <div class="resource-wrapper">
                                            <div class="room-name">
                                                @for (status of item.status; track status) {
                                                    <div class="availability" [ngStyle]="{ 'background-color': status.color }"></div>
                                                }
                                                @if (item.isAssignedTo | assignTo) {
                                                    <cue-icon [icon]="item.isAssignedTo | assignTo"></cue-icon>
                                                }
                                                {{
                                                    (item.resourceDisplayNameForApp | localizedString) ||
                                                        (item.resourceDisplayName | localizedString)
                                                }}
                                            </div>
                                            <div class="breadcrumb">
                                                @for (item of item.locations; track item; let i = $index; let last = $last) {
                                                    <span>
                                                        @if (i !== 0) {
                                                            <cue-icon [icon]="ICONS.cueArrowRight"></cue-icon>
                                                        }
                                                        {{ item.displayName }}
                                                    </span>
                                                }
                                            </div>
                                            <div class="availability-tag">
                                                <div
                                                    class="dot"
                                                    [class.available]="item.availableOutsideWorkingHours || item.isAvailable"
                                                    [class.unavailable]="!item.availableOutsideWorkingHours && !item.isAvailable"
                                                ></div>
                                                @if (item.availableOutsideWorkingHours || item.isAvailable) {
                                                    <span>{{ translation.outlookAddin.available | transloco }}</span>
                                                }
                                                @if (!item.availableOutsideWorkingHours && !item.isAvailable) {
                                                    <span>{{ translation.outlookAddin.unavailable | transloco }}</span>
                                                }
                                                <div class="info">
                                                    @if (!item.availableOutsideWorkingHours && !item.isAvailable) {
                                                        <cue-icon
                                                            [icon]="ICONS.cueInfo"
                                                            (mouseenter)="
                                                                changeCurrentOpenedTimelineDetail(item.resourceDisplayName, $event)
                                                            "
                                                            (mouseleave)="changeCurrentOpenedTimelineDetail(null, $event)"
                                                        >
                                                        </cue-icon>
                                                    }
                                                    @if (timelineDetailItemId === item.resourceDisplayName) {
                                                        <addin-resource-availability
                                                            [isRestrictedResource]="item.isAssignedTo === resourceAssignType.Restricted"
                                                            [timeline]="item.timeLine"
                                                            [startDate]="resourceFilter.start"
                                                            [endDate]="resourceFilter.end"
                                                        >
                                                        </addin-resource-availability>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="buttons">
                                            <button
                                                [disabled]="item.isAssignedTo === resourceAssignType.Restricted"
                                                (click)="selectDeselectResource(item.resourceId, $event, item.assignTo)"
                                                class="link"
                                                [class.primary]="!isResourceSelected(item.resourceId)"
                                                [class.error]="isResourceSelected(item.resourceId)"
                                            >
                                                @if (!isResourceSelected(item.resourceId)) {
                                                    <cue-icon
                                                        [icon]="ICONS.cueAction"
                                                        [title]="translation.outlookAddin.chooseResource | transloco"
                                                    ></cue-icon>
                                                }
                                                @if (isResourceSelected(item.resourceId)) {
                                                    <cue-icon
                                                        [icon]="ICONS.cueClose"
                                                        [title]="translation.outlookAddin.removeResource | transloco"
                                                    ></cue-icon>
                                                }
                                            </button>
                                            <button (click)="showResourceDetail(item.resourceId, $event)" class="link secondary">
                                                <cue-icon
                                                    [icon]="ICONS.cueSearch"
                                                    [title]="translation.outlookAddin.cueSearch | transloco"
                                                ></cue-icon>
                                            </button>
                                            @if (canNavigateToAssist) {
                                                <button
                                                    class="link secondary"
                                                    (click)="navigateToResourceDetailInAssist(item.resourceGuid, $event)"
                                                >
                                                    <cue-icon
                                                        [icon]="ICONS.cueExternalLink"
                                                        [title]="translation.outlookAddin.cueExternalLink | transloco"
                                                    ></cue-icon>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    </div>
}

@if (selectedResourceDetail) {
    <addin-resource-summary-detail
        (refresh)="onDetailRefresh()"
        (closeResourceDetail)="selectedResourceDetail = null"
        [resourceDetail]="resourceDetail$ | async"
        [isResourceSelected]="isResourceSelected(selectedResourceDetail.resourceId)"
        (selectDeselectResource)="selectDeselectResourceFromDetail($event)"
    >
    </addin-resource-summary-detail>
}
