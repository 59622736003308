import { createReducer, on } from '@ngrx/store';
import * as UserInfoActions from '../actions/user-info.actions';
import { UserInfo } from '@outlook-addin/cue-http';
import { Pack } from '@assist/shared/data';

export type State = {
  user: Pack<UserInfo>;
  isDifferentUserInOutlookAndAddin: boolean;
};

export const initialState: State = {
  user: {
    data: null,
    loading: false,
    error: null,
  },
  isDifferentUserInOutlookAndAddin: false,
};

// export const initialState: Pack<UserInfo> = {
//   data: null,
//   loading: false,
//   error: null
// };

export const reducer = createReducer(
  initialState,
  on(UserInfoActions.clearUserInfo, (state) => ({
    ...state,
    user: {
      data: null,
      loading: false,
    },
    isDifferentUserInOutlookAndAddin: false,
  })),
  on(UserInfoActions.loadUserInfo, (state) => ({
    ...state,
    user: {
      data: null,
      loading: true,
    },
    isDifferentUserInOutlookAndAddin: false,
  })),
  on(UserInfoActions.userInfoLoadSuccess, (state, { userInfo, foreignServer }) => ({
    ...state,
    user: {
      data: userInfo,
      loading: false,
      error: null,
    },
    isDifferentUserInOutlookAndAddin: foreignServer
      ? false
      : Office.context.mailbox.userProfile?.emailAddress != userInfo.email &&
        Office.context.mailbox.userProfile?.emailAddress != userInfo.username,
  })),
  on(UserInfoActions.userInfoLoadError, (state, { error }) => ({
    ...state,
    user: {
      data: null,
      loading: false,
      error: error,
    },
  })),
);
