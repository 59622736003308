<div class="content">
    @if (resourceDetail.loading) {
        <cue-loading></cue-loading>
    }
    @if (resourceDetail.error) {
        <div class="header">
            <button class="link secondary" (click)="closeResourceDetail.emit()">
                <cue-icon [icon]="ICONS.cueClose"></cue-icon>
            </button>
        </div>
        {{ translation.outlookAddin.errorDescription | transloco }}
        <button (click)="refresh.emit()" class="small">
            <cue-icon [icon]="ICONS.cueRefresh"></cue-icon>
            {{ translation.outlookAddin.refresh | transloco }}
        </button>
    }
    @if (!resourceDetail.loading && !resourceDetail.error) {
        <div class="header">
            <h2>
                {{ (resourceDetail.data?.displayNameForApp | localizedString) ?? (resourceDetail.data?.name | localizedString) }}
            </h2>
            <button class="link secondary" (click)="closeResourceDetail.emit()">
                <cue-icon [icon]="ICONS.cueClose"></cue-icon>
            </button>
        </div>
        <div class="detail-wrapper">
            <div>
                @if (resourceDetail.data?.isAssignedTo && resourceDetail.data?.isAssignedTo != 'free') {
                    <div class="assigned-to-label">
                        <cue-icon [icon]="resourceDetail.data!.isAssignedTo | assignTo"></cue-icon>
                        {{ resourceDetail.data!.isAssignedTo | assignToTranslateKey | transloco }}
                    </div>
                }
                @if (canShowLinkToAssist) {
                    @if (resourceDetail.data?.resourceGuid | resourceExternalUrl; as resourceUrl) {
                        <button class="link secondary" (click)="navigateToResourceDetailInAssist(resourceUrl)">
                            <cue-icon
                                [icon]="ICONS.cueExternalLink"
                                [title]="translation.outlookAddin.cueExternalLink | transloco"
                            ></cue-icon>
                            Open in Assist
                        </button>
                    }
                }
            </div>

            <div class="room-info">
                @if (resourceDetail) {
                    <table>
                        @if ((resourceDetail.data?.displayNameForApp | localizedString) && (resourceDetail.data?.name | localizedString)) {
                            <tr>
                                <th>
                                    <div>
                                        {{ translation.leftMenu.name | transloco }}
                                    </div>
                                </th>
                                <td>{{ resourceDetail.data?.name | localizedString }}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                                <div>
                                    {{ translation.resourceDetail.type | transloco }}
                                </div>
                            </th>
                            <td>{{ resourceDetail.data?.resourceTypeName | localizedString }}</td>
                        </tr>
                        <tr>
                            <th>
                                <div>
                                    {{ translation.resourceDetail.capacity | transloco }}
                                </div>
                            </th>
                            <td>{{ resourceDetail.data?.capacity }}</td>
                        </tr>
                        @for (simpleFilter of getSimpleFiltersByResourceTypeId($any(resourceDetail?.data)); track simpleFilter) {
                            <tr>
                                <th>
                                    <div>
                                        {{ simpleFilter.name | localizedString }}
                                    </div>
                                </th>
                                <td>
                                    @if (simpleFilter.value === true) {
                                        <cue-icon [icon]="ICONS.cueCheck" class="correct"></cue-icon>
                                    }

                                    @if (simpleFilter.value === false) {
                                        <cue-icon [icon]="ICONS.cueClose" class="incorrect"></cue-icon>
                                    }

                                    @if (simpleFilter.value.value !== true && simpleFilter.value.value !== false) {
                                        <div [innerHTML]="simpleFilter.value.value | localizedString | cueCustomUnsafeSanitize"></div>
                                    }
                                </td>
                            </tr>
                        }
                    </table>
                }
                @if (resourceDetail?.data) {
                    <div class="content-centerer-inner">
                        @for (enumerableFilter of getEnumerableFiltersByResourceTypeId($any(resourceDetail.data)); track enumerableFilter) {
                            <ul class="room-properties">
                                <div class="item-label">
                                    {{ enumerableFilter.name | localizedString }}
                                </div>
                                @for (value of enumerableFilter.value; track value) {
                                    <li>
                                        <div class="img-wrapper">
                                            <img
                                                [class.img-cover]="resourceDetail.data.imagePreferCover"
                                                [src]="value.imageUrl | imageWidth: 30"
                                            />
                                        </div>
                                        <span class="descriptionText">
                                            {{ value.title | localizedString }}
                                        </span>
                                    </li>
                                }
                            </ul>
                        }
                    </div>
                }
            </div>
            @if (resourceDetail.data?.daysOfWeek?.length > 0 && resourceDetail.data?.startTime && resourceDetail.data?.endTime) {
                <cue-working-hours [workingHourInfo]="resourceDetail.data" />
            }

            <div class="breadcrumb-container">
                @for (item of resourceDetail.data?.location; track item; let i = $index) {
                    <span class="breadcrumb">
                        @if (i !== 0) {
                            <cue-icon [icon]="ICONS.cueArrowRight"></cue-icon>
                        }
                        <span class="part">{{ item | localizedString }}</span>
                    </span>
                }
            </div>
            <addin-resource-map
                [geoData]="resourceDetail.data.mapGeoData"
                [imageSrc]="resourceDetail.data.mapUrl"
                [resourceId]="resourceDetail.data.resourceGuid"
            ></addin-resource-map>

            <div class="row">
                <div class="col-md-12" style="text-align: center">
                    <cue-image
                        [loadWidth]="400"
                        [imageClass]="'card-img-top'"
                        [imgHeight]="'180px'"
                        [imgMaxWidth]="'438px'"
                        [imgWidth]="'100%'"
                        [src]="currentImage ? currentImage : resourceDetail.data?.files.length > 0 ? resourceDetail.data?.files[0] : null"
                    >
                    </cue-image>
                    <div class="imgThumbsCont">
                        @for (image of resourceDetail.data?.files; track image) {
                            <cue-image
                                (click)="currentImage = image"
                                [loadWidth]="70"
                                [imageClass]="'card-img-top'"
                                [imgHeight]="'40px'"
                                [imgMaxWidth]="'70px'"
                                [imgWidth]="'70px'"
                                [src]="image"
                            ></cue-image>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <button
                [disabled]="resourceDetail.data?.isAssignedTo === resourceAssignType.Restricted"
                class="small"
                [class.error]="isResourceSelected"
                (click)="selectDeselectResource.emit(resourceDetail.data?.resourceId)"
            >
                @if (!isResourceSelected) {
                    <cue-icon [icon]="ICONS.cueAction"></cue-icon>
                    {{ translation.outlookAddin.chooseResource | transloco }}
                }
                @if (isResourceSelected) {
                    <cue-icon [icon]="ICONS.cueClose"></cue-icon>
                    {{ translation.outlookAddin.removeResource | transloco }}
                }
            </button>
        </div>
    }
</div>
