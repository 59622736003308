import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as fromShared from '../ngrx/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { cueLogin, IconComponent } from '@cue/ui/icons';
import { AccountActions } from '../ngrx/actions';
import { OutlookLoginMode, STORAGE_KEYS } from '@outlook-addin/cue-http';
import { AddinConfigService } from '@outlook-addin/shared';
import { translation } from '@assist/shared/translations';
import * as fromModule from '../ngrx/reducers';

import { TranslocoModule } from '@ngneat/transloco';

import AsyncResultStatus = Office.AsyncResultStatus;
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'addin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [TranslocoModule, IconComponent, AsyncPipe],
})
export class LoginComponent implements OnInit {
  outlookUserDifferentFromAddin$ = this.store.select(fromModule.selectOutlookUserDifferentFromAddin);

  protected readonly cueLogin = cueLogin;
  protected readonly translation = translation;

  get loginUrl(): string {
    return `https://login.microsoftonline.com/${this.configService.value.configuration.tenantId}/oauth2/v2.0/authorize?client_id=${this.configService.value.configuration.clientId}
    &scope=Mail.Read offline_access openid profile User.Read&response_type=code&prompt=${this.configService.value.configuration.prompt}&redirect_uri=${`https://${window.location.host}/Authorize2`}`;
  }
  msUserLoggedIn$: Observable<boolean>;

  userAuthorized: boolean = false;
  constructor(
    private router: Router,
    private store: Store<fromShared.State>,
    private configService: AddinConfigService,
    private zone: NgZone,
  ) {
    this.msUserLoggedIn$ = this.store.select(fromShared.selectIsMsUserLoggedIn);
  }

  ngOnInit() {
    //due to problem with RouterModule.forRoot(routes, {useHash: true,}) get params does not work
    this.getCodeFromUrl();
  }

  login() {
    console.log('login login login');
    //TODO:Jakub refactor - presunout nekde do service ale aby byl dostupny Office objekt

    this.zone.run(() => {
      Office.onReady().then((x) => {
        Office.context.ui.displayDialogAsync(this.loginUrl, { displayInIframe: false }, (asyncResult) => {
          console.log('Office.context.ui.displayDialogAsync CALLBACK');
          console.log(asyncResult);
          if (asyncResult.status === AsyncResultStatus.Succeeded) {
            const dialog = asyncResult.value;
            dialog.addEventHandler(Office.EventType.DialogEventReceived, (args) => {
              //this.store.dispatch(AccountActions.loadTokenRefreshToken({route: true}));
              localStorage.setItem(STORAGE_KEYS.outlookLoginMode, OutlookLoginMode.interactive);
            });
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args) => {
              console.log('RECEIVE CODE FROM CHILD!!!');
              const code = (args as unknown as any).message;
              localStorage.setItem(STORAGE_KEYS.code, code);
              this.store.dispatch(AccountActions.loadTokenRefreshToken({ route: true }));
            });
          }
        });
      });
    });
  }

  private getCodeFromUrl() {
    if (!localStorage.getItem(STORAGE_KEYS.code)) {
      //URL looks like https://localhost:4200/?code=123&session_state=aaa
      const uriPartWithCodeParam = window.location.search;
      const codeWithOtherParams = uriPartWithCodeParam?.split('?code=');

      //if code was found in URL
      if (codeWithOtherParams?.length === 2) {
        this.userAuthorized = true;
        //trim session_state params from URI
        const codeParam = codeWithOtherParams[1].split('&session_state=');
        localStorage.setItem(STORAGE_KEYS.code, codeParam[0]);
        // window.top.postMessage('code_received', '*');
        this.router.navigateByUrl('reservations', { skipLocationChange: true });
      } else {
        this.userAuthorized = false;
      }
    }
  }
}
