import { Component, Input } from '@angular/core';
import { IEventInfoOrderBm } from '@outlook-addin/cue-http';
import { translation } from '@assist/shared/translations';
import { cueArrowDown, IconComponent } from '@cue/ui/icons';

import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'addin-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  imports: [TranslocoModule, IconComponent],
})
export class OrderDetailComponent {
  ICONS = {
    cueArrowDown,
  };
  translation = translation;

  shrinkItemIndexList: number[] = [];
  @Input() orderList: IEventInfoOrderBm[];

  isItemShrinked(itemIndex: number) {
    return this.shrinkItemIndexList.indexOf(itemIndex) > -1;
  }

  expandShrinkGroup(itemIndex: number) {
    const itemIxdInList = this.shrinkItemIndexList.indexOf(itemIndex);
    if (itemIxdInList > -1) {
      this.shrinkItemIndexList.splice(itemIxdInList, 1);
    } else {
      this.shrinkItemIndexList.push(itemIndex);
    }
  }
}
